import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { PageWrapper } from "~components/Core"
import FooterSection from "~sections/utility/Footer"
import { CopyLinkButton } from "./Component"
import Details from "./style"
import { FacebookShareButton, TwitterShareButton } from "react-share"

const header = {
  headerClasses: "position-relative",
}

export default function BlogDetails({ data, location }) {
  const post = data.markdownRemark
  const {
    title,
    description,
    date,
    formattedDate,
    hero_image,
    hero_image_alt,
    hero_image_credit_link,
    hero_image_credit_author,
    hero_image_credit_author_link,
  } = post.frontmatter
  const url = location.href
  const socialTitle = `${title} - Monsai`
  const image = getImage(hero_image)
  const imageURL = `https://monsaiapp.com${image.images.fallback.src}`

  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Helmet>
        <title>{socialTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={socialTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={imageURL} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageURL} />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            headline: title,
            url: url,
            datePublished: date,
            dateCreated: date,
            dateModified: date,
            description: description,
            publisher: {
              "@type": "Organization",
              name: "Monsai",
            },
            image: {
              "@type": "ImageObject",
              url: imageURL,
              width: 1200,
              height: 630,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": url,
            },
          })}
        </script>
      </Helmet>
      <Details backgroundColor="#f9fafc">
        <Details.Box pb="60px" pbMD="80px" pbLG="130px">
          <Container>
            <Row className="justify-content-center">
              <Col className="col-lg-8">
                <Details.Box mb="30px" mbLG="55px">
                  <Details.Title as="h1">{title}</Details.Title>
                  <Details.MetaInfo>
                    <Details.Link>{formattedDate}</Details.Link>
                  </Details.MetaInfo>
                </Details.Box>
                <Details.Box mb="30px" mbLG="55px">
                  <figure>
                    <GatsbyImage image={image} alt={hero_image_alt} />
                    {!!hero_image_credit_link && (
                      <figcaption>
                        Photo by{" "}
                        <a href={hero_image_credit_author_link}>
                          {hero_image_credit_author}
                        </a>{" "}
                        on <a href={hero_image_credit_link}>Unsplash</a>
                      </figcaption>
                    )}
                  </figure>
                </Details.Box>
                <Details.Box>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                  <Details.Tag mt="30px">
                    <Details.SubTitle mr="25px" as="div">
                      Share:
                    </Details.SubTitle>
                    <Details.SocialList>
                      <li>
                        <FacebookShareButton
                          url={url}
                          quote={socialTitle}
                          aria-label="Share on Facebook"
                        >
                          <i className="fab fa-facebook" />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={url}
                          title={socialTitle}
                          via="MonsaiApp"
                          aria-label="Share on Twitter"
                        >
                          <i className="fab fa-twitter" />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <CopyLinkButton url={url} aria-label="Copy link" />
                      </li>
                    </Details.SocialList>
                  </Details.Tag>
                </Details.Box>
              </Col>
            </Row>
          </Container>
        </Details.Box>
      </Details>
      <FooterSection />
    </PageWrapper>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "YYYY-MM-DDTHH:mm:ssZ") # Raw date for schema
        formattedDate: date(formatString: "MMMM DD, YYYY") # For display
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_author
        hero_image_credit_author_link
      }
    }
  }
`
